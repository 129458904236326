import { EarningsResponse } from "../../api/model";
import { DateTime } from "luxon";
import { fetchEarnings } from "../../api/quries/earningEvent";

const groupByDate = (earnings: EarningsResponse[]) => {
  return earnings.reduce((acc, earning) => {
    const date = earning.eventAt.setZone("America/New_York").startOf("day").toFormat("yyyy-MM-dd");
    acc[date] = acc[date] || [];
    acc[date].push(earning);
    return acc;
  }, {} as Record<string, EarningsResponse[]>);
};

export const fetchGroupedEarnings = async (from: DateTime, to: DateTime): Promise<Record<string, EarningsResponse[]>> => {
  const earnings = await fetchEarnings(from, to);
  const groups = groupByDate(earnings.filter((earning) => earning.ticker !== "GOOGL" && earning.ticker !== "ZG"));
  for (const date in groups) {
    var original = groups[date];
    original.sort((a, b) => b.marketCap - a.marketCap);
  }
  return groups;
};
