// create react component
import styled from "@emotion/styled";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchConferenceCallById } from "../../api/quries/earningEvent";
import { Colors } from "../../component/Color";
import Typography from "../../component/Typography";
import MediumButton from "../../component/buttons/MediumButton";
import AudioPlayer from "./AudioPlayer";

import { EarningsCallTranscript, EarningsConferenceCall } from "../../api";
import TickerItem from "../../component/ticker-item";
import IconCall from "./images/call.svg";
import IconCallSelected from "./images/call_selected.svg";
import IconEndCall from "./images/end_call.svg";
import IconMoveToCurScriptDown from "./images/move_to_cur_script_down.svg";
import IconMoveToCurScriptUp from "./images/move_to_cur_script_up.svg";
import IconSummary from "./images/summary.svg";
import IconSummarySelected from "./images/summary_selected.svg";

import ToggleButton from "../../component/buttons/ToggleButton";
import TickerIcon from "../../component/icon/TickerIcon";
import { easeOutQuart } from "../../component/transition";
import { DateTimeUtils, getElapsedTimeText } from "../../util/time";
import ChooseEarnings from "./ChooseEarnings";
import NoTranscript from "./NoTranscript";
import { LiveSummary } from "./LiveSummary";

interface Props {
  earningsId?: number;
}

const Container = styled.div`
  position: relative;
  flex: 1;
  padding: 20px;
  overflow: hidden;

  min-width: 700px;
`;

const Script = styled.div<{ selected: boolean }>`
  display: flex;
  gap: 10px;
  background-color: ${(props) => (props.selected ? "#ececec" : "none")};
`;

const AudioPlayerContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ToggleButtonContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 22px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 46px;

  position: relative;
  padding-bottom: 20px;
`;

const TickerItemContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const ScriptScrollContainer = styled.div`
  height: calc(100vh - 600px);
  width: 100%;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 20px 300px 20px;

  user-select: text;

  > div {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
  }
`;

const NoTranscriptContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
  min-height: 600px;
  min-width: 500px;

  position: relative;
`;

const ScriptContainer = styled.div`
  margin: 15px 0;
  background-color: #191919;
  padding: 30px;
  border-radius: 30px;

  > span {
    margin-right: 8px;
  }
`;

const SummaryScriptContainer = styled(ScriptContainer)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;

  padding-right: 50px;
`;

const ScriptSpeakerIcon = styled.div`
  width: 40px;
  height: 40px;
  margin: 20px 10px 0 0;
`;

const EndRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 50px;

  > div > img {
    margin-right: 8px;
  }
`;

const EndItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const SummaryTitle = styled.div`
  width: fit-content;
  padding: 8px 16px 8px 16px;
  border-radius: 60px;
  background-color: ${Colors.gray90};
`;

const TopGradient = styled.div`
  position: absolute;
  top: 80px;
  width: 100%;
  height: 80px;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
`;

const BottomGradient = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 200px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  pointer-events: none;
`;

const SeekToNowContainer = styled.div<{ useUpArrow: boolean }>`
  position: absolute;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
`;

async function fetchTranscript(url: string) {
  try {
    // read the file from url
    const response = await fetch(url);
    const text = await response.text();
    return text.split("\n").map((line: string) => JSON.parse(line));
  } catch (e) {
    console.log(`fetch transcript error, url: ${url}`, e);
    return [];
  }
}

const scrollingStateStore: {
  earningsCallId: string;
  userScrolling: boolean;
  lastScrolledSentenceStartTime: number;
  lastAutoScrolledTime: number;
  currentElementPosition: number;
  useUpArrow: boolean;
} = {
  earningsCallId: "",
  userScrolling: false,
  lastAutoScrolledTime: 0,
  lastScrolledSentenceStartTime: 0,
  currentElementPosition: 0,
  useUpArrow: false,
};

const SummaryKeys = ["summary", "outlook", "guidance", "executiveComment", "qna"];
const SummaryTitles = {
  summary: "실적 요약",
  outlook: "업황 전망",
  guidance: "가이던스",
  executiveComment: "경영진 별 커멘트",
  qna: "Q&A 요약",
};

var globalTranslationToastShown = true;

export default function EarningsCallTranscriptPlayer(props: Props) {
  const { earningsCallId } = useParams();
  const navigate = useNavigate();
  const [script, setScript] = useState<EarningsCallTranscript[]>([]);
  const [scriptGroup, setScriptGroup] = useState<EarningsCallTranscript[][]>([]);
  const [curAudioState, setCurAudioState] = useState<"play" | "pause">("pause");
  const [curAudioTime, setCurAudioTime] = useState(0);
  const [tab, setTab] = useState<"call" | "summary">("call");
  const [scrollPositions, setScrollPositions] = useState<{ [key: string]: number }>({});
  const [requestSeekTime, setRequestSeekTime] = useState(0);
  const [translationEnabled, setTranslationEnabled] = useState(true);

  const [translationToastShown, setTranslationToastShown] = useState(globalTranslationToastShown);

  const {
    data: conferenceCall,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["fetchConferenceCallById", earningsCallId],
    queryFn: () => fetchConferenceCallById(earningsCallId),
  });

  const buildSentenceElementId = (time: number) => {
    return `t_${time}`;
  };
  const isLive = conferenceCall?.liveAudioState === "live";
  const hasTranscript = conferenceCall?.koLiveTranscriptUrl !== undefined && conferenceCall?.koLiveTranscriptUrl !== null;
  const isPast = conferenceCall?.eventAt && DateTimeUtils.isDateInPast(conferenceCall.eventAt);
  const endElement = script[script.length - 1]?.type === "end" && script[script.length - 1];

  const onProgress = (curTime: number, duration: number, playState: "play" | "pause") => {
    setRequestSeekTime(0);
    setCurAudioTime(curTime);
    setCurAudioState(playState);

    scrollToCurrentAudioTimePosition(curTime);
  };

  const scrollToCurrentAudioTimePosition = (curTime: number, log: boolean = false) => {
    const findCurLine = (script: EarningsCallTranscript[], curTime: number) => {
      var lastScriptEnd = 0;
      for (const l of script) {
        if (lastScriptEnd <= curTime && l.e && curTime <= l.e) {
          return l;
        }

        lastScriptEnd = l.e || 0;
      }

      if (lastScriptEnd <= curTime) {
        return script[script.length - 1];
      }
      return undefined;
    };

    if (!scrollingStateStore.userScrolling) {
      const line = findCurLine(script, curTime);
      if (line?.s && line?.s !== scrollingStateStore.lastScrolledSentenceStartTime) {
        document.getElementById(buildSentenceElementId(line.s))?.scrollIntoView({ behavior: "smooth", block: "center" });
        scrollingStateStore.lastAutoScrolledTime = Date.now();
        scrollingStateStore.lastScrolledSentenceStartTime = line.s;
      }
    } else {
      const line = findCurLine(script, curTime);
      if (line?.s) {
        const id = buildSentenceElementId(line.s);
        const currentElement = document.getElementById(id);
        const scrollContainer = document.getElementById("scroll-container");
        if (currentElement?.getClientRects()?.item(0)?.top && scrollContainer?.getClientRects()?.item(0)?.top) {
          const currentElementRect = currentElement?.getClientRects()?.item(0);
          const scrollContainerRect = scrollContainer?.getClientRects()?.item(0);

          if (currentElementRect && scrollContainerRect) {
            const intersectionRect = currentElementRect.top < scrollContainerRect.bottom && currentElementRect.bottom > scrollContainerRect.top;
            if (intersectionRect) {
              scrollingStateStore.userScrolling = false;
            } else {
              scrollingStateStore.useUpArrow = currentElementRect.top < scrollContainerRect.bottom;
            }
          }
        }
      }
    }
  };

  const fetchAndUpdateScript = async (conferenceCall?: EarningsConferenceCall) => {
    if (conferenceCall?.koLiveTranscriptUrl) {
      const lines: EarningsCallTranscript[] = await fetchTranscript(conferenceCall?.koLiveTranscriptUrl);
      const groupedLines = lines.reduce((acc: { [key: string]: EarningsCallTranscript[] }, line) => {
        if (line.p) {
          (acc[line.p] = acc[line.p] || []).push(line);
        }
        return acc;
      }, {});
      const script = Object.values(groupedLines);
      setScriptGroup(script);
      setScript(lines);
    } else {
      setScriptGroup([]);
      setScript([]);
    }
  };

  useEffect(() => {
    if (curAudioState === "play") {
      scrollingStateStore.userScrolling = false;
    }
  }, [curAudioState]);

  useEffect(() => {
    if (earningsCallId && scrollingStateStore.earningsCallId !== earningsCallId) {
      scrollingStateStore.userScrolling = false;
      scrollingStateStore.lastScrolledSentenceStartTime = 0;
      scrollingStateStore.earningsCallId = earningsCallId;
      setTab("call");
    }
  }, [earningsCallId]);

  useEffect(() => {
    (async () => {
      fetchAndUpdateScript(conferenceCall);
    })();
  }, [conferenceCall]);

  // 10초에 한번 라이브 활성 여부 체크
  useEffect(() => {
    const liveAndSupportLiveSummary = isLive && conferenceCall?.supportLiveSummary;
    if ((!isLive && !isPast) || (isLive && !hasTranscript) || liveAndSupportLiveSummary) {
      const intervalId = setInterval(async () => {
        console.log("fetch info");
        refetch();
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [conferenceCall, hasTranscript, isLive, isPast, refetch]);

  useEffect(() => {
    if (isLive && conferenceCall?.supportLiveSummary) {
      const intervalId = setInterval(async () => {
        console.log("fetch live summary info");
        refetch();
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [conferenceCall, isLive, isPast, refetch]);

  useEffect(() => {
    if (isLive) {
      const intervalId = setInterval(async () => {
        fetchAndUpdateScript(conferenceCall);
      }, 1000);
      // clean-up시 clearInterval을 사용하여 인터벌 정리
      return () => clearInterval(intervalId);
    }
  }, [conferenceCall, isLive]);

  useEffect(() => {
    const scrollContainer = document.getElementById("scroll-container");
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollPositions[tab] || 0;
    }
  }, [tab, scrollPositions]);

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (curAudioState === "play" && Date.now() - scrollingStateStore.lastAutoScrolledTime > 1000) {
      scrollingStateStore.userScrolling = true;
      scrollingStateStore.lastScrolledSentenceStartTime = 0;
    }
  };

  const seekAudioTo = (time: number) => {
    setRequestSeekTime(time);
  };

  const handleTabChange = (newTab: "call" | "summary") => {
    const currentScrollPosition = document.getElementById("scroll-container")?.scrollTop || 0;
    setScrollPositions((prev) => ({ ...prev, [tab]: currentScrollPosition }));
    setTab(newTab);
  };

  function onUserInteraction(eventType: "quickSeek" | "progressSeek"): void {
    scrollingStateStore.userScrolling = false;
  }

  const moveToCurrentPositionDirectly = () => {
    scrollingStateStore.userScrolling = false;
    scrollToCurrentAudioTimePosition(curAudioTime, true);
  };

  useEffect(() => {
    if (hasTranscript && tab === "call") {
      const id = setTimeout(() => {
        setTranslationToastShown(false);
        globalTranslationToastShown = false;
      }, 2000);
      return () => clearTimeout(id);
    }
  }, [hasTranscript, tab]);

  return (
    <Container>
      <HeaderContainer>
        <TickerItemContainer>
          {conferenceCall && <TickerItem style={{ padding: 0 }} earnings={conferenceCall} selected={true} hideControls />}
        </TickerItemContainer>
        {hasTranscript && (conferenceCall?.summary || conferenceCall?.supportLiveSummary) && (
          <Tabs>
            <MediumButton
              icon={IconCall}
              selectedIcon={IconCallSelected}
              selected={tab === "call"}
              text="어닝콜"
              onClick={() => handleTabChange("call")}
              border
            />
            <MediumButton
              icon={IconSummary}
              selectedIcon={IconSummarySelected}
              selected={tab === "summary"}
              text={conferenceCall?.summary ? "Ai요약" : "실시간 요약"}
              onClick={() => handleTabChange("summary")}
              border
            />
          </Tabs>
        )}
      </HeaderContainer>
      {hasTranscript && (
        <>
          <ScriptScrollContainer onScroll={onScroll} id="scroll-container">
            {tab === "call" ? (
              <div key="tab_call">
                {scriptGroup.map((line, idx: number) => {
                  return (
                    <Row key={`p_${idx}`}>
                      <ScriptSpeakerIcon>
                        <TickerIcon iconUrl={conferenceCall?.tickerIconUrl} name={conferenceCall?.ticker || ""} style={{ width: 40, height: 40 }} />
                      </ScriptSpeakerIcon>
                      <ScriptContainer key={`p_${idx}`} id={"p_" + idx}>
                        {line.map((l) => (
                          <Typography
                            style={{ cursor: "pointer" }}
                            variant="10/medium"
                            color={curAudioTime > 0 && curAudioTime >= l.s! ? Colors.white : Colors.gray60}
                            key={`${l.type}_${l.s}_${l.e}`}
                            id={buildSentenceElementId(l.s!)}
                            onClick={() => {
                              seekAudioTo(l.s!);
                            }}
                          >
                            {translationEnabled ? l.tr : l.t}
                          </Typography>
                        ))}
                      </ScriptContainer>
                    </Row>
                  );
                })}
                {endElement && (
                  <EndRow>
                    <ScriptContainer>
                      <EndItem>
                        <img src={IconEndCall} alt="call" />
                        <Typography variant="10/medium" color={Colors.gray60}>
                          통화 종료
                        </Typography>
                      </EndItem>
                    </ScriptContainer>
                  </EndRow>
                )}
              </div>
            ) : (
              <div key="tab_summary">
                {conferenceCall?.summary &&
                  SummaryKeys.map((key) => {
                    const values = (conferenceCall?.summary as any)[key];
                    if (values.length > 0) {
                      return (
                        <SummaryScriptContainer key={`summary_${key}`}>
                          <SummaryTitle>
                            <Typography variant="8/semibold">{SummaryTitles[key as keyof typeof SummaryTitles]}</Typography>
                          </SummaryTitle>
                          {values.map((line: string) => (
                            <Script selected={false}>
                              <div>
                                <Typography variant="10/medium" color={Colors.gray30}>
                                  •
                                </Typography>
                              </div>
                              <Typography variant="10/medium" color={Colors.gray30}>
                                {line}
                              </Typography>
                            </Script>
                          ))}
                        </SummaryScriptContainer>
                      );
                    }
                  })}

                <LiveSummary conferenceCall={conferenceCall} showTitle={conferenceCall?.summary} />
              </div>
            )}
          </ScriptScrollContainer>

          <TopGradient />
          <BottomGradient />
        </>
      )}
      {conferenceCall?.audioUrl && (
        <>
          <AudioPlayerContainer>
            <AudioPlayer
              src={conferenceCall?.audioUrl}
              onProgress={onProgress}
              requestSeekTime={requestSeekTime}
              qnaTime={conferenceCall?.qnaTimestamp}
              isLive={isLive}
              onUserInteraction={onUserInteraction}
              wentLiveAt={conferenceCall?.eventAt?.toMillis()}
            />
          </AudioPlayerContainer>
        </>
      )}

      {conferenceCall && hasTranscript && tab === "call" && curAudioState === "play" && scrollingStateStore.userScrolling && (
        <SeekToNowContainer useUpArrow={scrollingStateStore.useUpArrow}>
          <MediumButton
            icon={scrollingStateStore.useUpArrow ? IconMoveToCurScriptUp : IconMoveToCurScriptDown}
            text="현재 위치로"
            onClick={moveToCurrentPositionDirectly}
            selected
          />
        </SeekToNowContainer>
      )}

      {hasTranscript && tab === "call" && (
        <ToggleButtonContainer>
          <TranslationToastContainer style={{ opacity: translationToastShown ? 1 : 0 }}>
            <Typography variant="4/regular" color={Colors.gray50}>
              AI를 이용한 번역으로, 일부 표현이 정확하지 않을 수 있습니다.
            </Typography>
          </TranslationToastContainer>
          <ToggleButton text={"번역"} active={translationEnabled} onClick={() => setTranslationEnabled(!translationEnabled)} />
        </ToggleButtonContainer>
      )}

      {conferenceCall ? (
        conferenceCall &&
        !hasTranscript && (
          <NoTranscriptContainer>
            <NoTranscript isPast={isPast || false} isLive={isLive} earningsCall={conferenceCall} />
          </NoTranscriptContainer>
        )
      ) : (
        <NoTranscriptContainer>
          <ChooseEarnings />
        </NoTranscriptContainer>
      )}
    </Container>
  );
}

const TranslationToastContainer = styled.div`
  position: absolute;
  bottom: 60px;
  right: 0px;
  width: 207px;

  padding: 10px 16px 10px 16px;
  gap: 10px;
  border-radius: 16px;
  background-color: ${Colors.gray90};
  opacity: 0;

  transition: opacity 1s ${easeOutQuart};
  pointer-events: none;
`;
